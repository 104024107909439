

var endpoint = '/professional'
export default class ProfessionalService {

  constructor(axios){
    this.axios = axios
  }

  list(params) {
    return this.axios.get(`${endpoint}/`, { params })
  }

  listByName(name, params) {
    return this.axios.get(`${endpoint}/name/${name}`, { params })
  }

  save(professional) {
    if (professional.id) {
      return this.axios.put(`${endpoint}/${professional.id}`, professional)
    } else {
      return this.axios.post(`${endpoint}/`, professional)
    }
  }

  delete(id) {
    return this.axios.delete(`${endpoint}/${id}`)
  }

  get(id) {
    return this.axios.get(`${endpoint}/${id}`)
  }
}
