

var endpoint = '/room'
export default class RoomService {

  constructor(axios){
    this.axios = axios
  }

  list(params) {
    return this.axios.get(`${endpoint}/`, { params })
  }

  listByName(name, params) {
    return this.axios.get(`${endpoint}/name/${name}`, { params })
  }

  save(room) {
    if (room.id) {
      return this.axios.put(`${endpoint}/${room.id}`, room)
    } else {
      return this.axios.post(`${endpoint}/`, room)
    }
  }

  delete(id) {
    return this.axios.delete(`${endpoint}/${id}`)
  }

  get(id) {
    return this.axios.get(`${endpoint}/${id}`)
  }
}
