

var endpoint = '/procedure'
export default class ProcedureService {

  constructor(axios){
    this.axios = axios
  }

  list(params) {
    return this.axios.get(`${endpoint}/`, { params })
  }

  listByProfessional(params) {
    return this.axios.get(`${endpoint}/byprofessional`, { params })
  }

  save(procedure) {
    
    if (procedure.id) {
      return this.axios.put(`${endpoint}/${procedure.id}`, procedure)
    } else {
      return this.axios.post(`${endpoint}/`, procedure)
    }
  }

  delete(id) {
    return this.axios.delete(`${endpoint}/${id}`)
  }

  get(id) {
    return this.axios.get(`${endpoint}/${id}`)
  }

  changeStatus(id, newStatus){
    return this.axios.put(`${endpoint}/change_status/${id}`, newStatus)
  }
  
  history(id) {
    return this.axios.get(`${endpoint}/hist/${id}`)
  }
}
